import { useState } from 'react';
import classNames from 'classnames';

import { useWindowContext, smWidth } from 'contexts/window';
import exportCsvFromTable from 'utils/exports/exportCsvFromTable';
import InfiniteScrollLoader from 'components/elements/Loader/InfiniteScrollLoader';

import TableHead from './components/TableHead';
import TableBody from './components/TableBody';
import CardList from './components/CardList';
import { TableProps, OnSortChange } from './types';
import defaultSortFunction from './defaultSortFunction';
import styles from './index.module.css';

const ROWS_PER_PAGE = 40;

const defaultProps: Partial<TableProps> = {
  rowKeyProp: 'id',
  sort: undefined,
  // TODO Deprecated, use table column prop getSortValue
  sortFunction: defaultSortFunction,
  onRowClick: undefined,
  getRowActions: undefined,
  getRowStatus: undefined,
  isRowSelectable: undefined,
  exportActions: undefined,
  rowDetail: undefined,
  hideTableHead: false,
  alwaysDisplayActions: false,
  testId: 'table',
};

function DataTable<T>({
  columns,
  rows,
  rowKeyProp,
  sort,
  // TODO Deprecated, use table column prop getSortValue
  sortFunction,
  onRowClick,
  onSortChange,
  getRowActions,
  getRowStatus,
  isRowSelectable,
  className,
  exportActions,
  rowDetail,
  hideTableHead,
  alwaysDisplayActions,
  name,
  testId,
}: TableProps<T>) {
  const { winWidth } = useWindowContext();
  const [rowCount, setRowCount] = useState(ROWS_PER_PAGE);

  const getCurrentSortValue = columns.find(
    (column) => (column.sortProp || column.prop) === sort?.prop,
  )?.getSortValue;

  const slicedRows = sortFunction(rows, sort, getCurrentSortValue).slice(
    0,
    rowCount,
  );
  const cols = columns.filter((column) => winWidth > (column.minWidth ?? 0));

  let headExportActions = exportActions;

  if (exportActions === undefined) {
    headExportActions = [
      {
        id: 'export-csv',
        icon: 'csv',
        text: 'Export to CSV',
        onClick: () =>
          exportCsvFromTable(
            columns,
            rows,
            undefined,
            name ? `${name}.csv` : 'export.csv',
            rowDetail?.exportCsv,
          ),
      },
    ];
  }

  const showMoreRows = () => {
    setRowCount((count) => count + ROWS_PER_PAGE);
  };

  const handleSortChange: OnSortChange = onSortChange
    ? (...args) => {
        // Reset pagination when user changes sorting options
        setRowCount(ROWS_PER_PAGE);
        onSortChange(...args);
      }
    : null;

  const scrollLoader =
    rowCount < rows.length ? (
      <InfiniteScrollLoader onTrigger={showMoreRows} />
    ) : null;

  if (winWidth < smWidth) {
    return (
      <>
        <CardList
          columns={cols}
          rows={slicedRows}
          rowKeyProp={rowKeyProp}
          onRowClick={onRowClick}
          getRowActions={getRowActions}
          renderRowDetail={rowDetail?.render}
          isRowSelectable={isRowSelectable}
        />
        {scrollLoader}
      </>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <table className={styles.table} data-testid={testId}>
        {!hideTableHead && (
          <TableHead
            columns={cols}
            sort={sort}
            onSortChange={handleSortChange}
            exportActions={headExportActions}
            hasRowDetail={Boolean(rowDetail)}
          />
        )}
        <TableBody
          columns={cols}
          rows={slicedRows}
          rowKeyProp={rowKeyProp}
          onRowClick={onRowClick}
          getRowActions={getRowActions}
          getRowStatus={getRowStatus}
          alwaysDisplayActions={alwaysDisplayActions}
          renderRowDetail={rowDetail?.render}
          openedRowsDetailByDefault={rowDetail?.openedRowsDetailByDefault}
          isRowSelectable={isRowSelectable}
        />
      </table>
      {scrollLoader}
    </div>
  );
}

DataTable.defaultProps = defaultProps;

export default DataTable;
