import { useRef, useEffect, useState, KeyboardEvent } from 'react';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';
import useRandomHtmlId from 'utils/useRandomHtmlId';
import Portal from 'components/composed/Portal';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import { Props } from './types';
import styles from './styles.module.css';

const dialogTitleIdPrefix = 'dialog-title-';
const dialogDescriptionIdPrefix = 'dialog-description-';
export const dialogDataTestId = 'dialog-testid';

const Dialog = ({
  title,
  children,
  confirmText,
  cancelText,
  confirmLabel,
  onConfirm,
  onCancel,
  dataTestId,
  role,
}: Props) => {
  const ref = useRef<HTMLDivElement>();

  const [busy, setBusy] = useState(false);
  const dialogDescriptionId = useRandomHtmlId(dialogDescriptionIdPrefix);

  const dialogTitleId = useRandomHtmlId(dialogTitleIdPrefix);

  const handleConfirm = async (e) => {
    e.stopPropagation();
    setBusy(true);
    await onConfirm();
    onCancel();
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    if (!busy) onCancel();
  };

  const handleOutsideClick = (e) => {
    handleCancel(e);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleConfirm(e);
    } else if (e.key === 'Escape') {
      handleCancel(e);
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    ref?.current?.focus();
  }, []);

  return (
    <Portal element={document.body}>
      <section className={styles.container} data-testid={dataTestId}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
        <div
          ref={ref}
          onClick={handleOutsideClick}
          className={styles.overlay}
          onKeyDown={handleKeyPress}
        />
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            fallbackFocus: `div[role=${role}]`,
          }}
        >
          <div
            role={role}
            aria-modal="true"
            className={styles.box}
            aria-labelledby={dialogTitleId}
            aria-describedby={dialogDescriptionId}
            data-testid={dialogDataTestId}
          >
            <div
              className={classNames(styles.title, 'textLSemibold')}
              aria-label={title}
              id={dialogTitleId}
            >
              {title}
            </div>
            <div
              className={classNames(styles.content, 'textL')}
              id={dialogDescriptionId}
            >
              {children}
            </div>
            {confirmText ? (
              <Button
                label={confirmLabel ?? confirmText}
                onClick={handleConfirm}
                wrapperClassName={styles.confirmButton}
                size={ButtonSize.Large}
                testId="confirmBtn"
                loading={busy}
              />
            ) : null}
            {cancelText ? (
              <Button
                label={cancelText}
                onClick={handleCancel}
                wrapperClassName={styles.cancelButton}
                theme={ButtonTheme.Ghost}
                size={ButtonSize.Large}
                testId="cancelBtn"
                disabled={busy}
              />
            ) : null}
          </div>
        </FocusTrap>
      </section>
    </Portal>
  );
};

Dialog.defaultProps = {
  confirmText: 'Confirm',
  confirmLabel: undefined,
  cancelText: 'Cancel',
  dataTestId: 'dialog',
  role: 'dialog',
};

export default Dialog;
